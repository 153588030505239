import { toastr } from "react-redux-toastr";
import React, { useState, useCallback, useEffect } from "react";
import { useRef } from "react";
import FormLabel from "../../../../components/FormLabel";
import { Card, Row, Label } from "reactstrap";

import {
  FormButton,
  FormCheckbox,
  IntegerInput,
  TextInput,
  RadioGroup,
  AsyncComboBox,
  ModalBase,
  ComboBox,
} from "../../../../components";
import { apiGet, apiPost } from "../../../../api";

const ladosAbertura = [
  { label: "Direita", value: "DIR" },
  { label: "Esquerda", value: "ESQ" },
];

const espessurasPorta = [
  { label: "36mm", value: "36" },
  { label: "41mm", value: "41" },
];

const organizaVistas = [
  { label: "1 Fixa 1 Regulável", value: "1F1R" },
  { label: "2 Fixas", value: "2F" },
  { label: "2 Reguláveis", value: "2R" },
];

function IncluirKitPortasAbrir({
  idPedidoCab,
  abrirCab,
  params,
  tipoMaterial,
  buscarItensPedido,
  markup,
  qtdPortas,
}) {
  const [numPortasIncluir, setNumPortasIncluir] = useState(1);
  const [nomePorta, setNomePorta] = useState("");
  const [ladoAbertura, setLadoAbertura] = useState();
  const [espessuraPorta, setEspessuraPorta] = useState(
    espessurasPorta[0].value
  );
  const [altura, setAltura] = useState(null);
  const [largura, setLargura] = useState(null);
  const [espessura, setEspessura] = useState(null);
  const [cor, setCor] = useState(null);
  const [dobradica, setDobradica] = useState(null);
  const [fechadura, setFechadura] = useState(null);
  const [larguraVista, setLarguraVista] = useState(null);
  const [qtdVistas, setQtdVistas] = useState("1F1R");
  const [negativo, setNegativo] = useState(null);
  const [usinagensEspeciais, setUsinagensEspeciais] = useState([]);
  const [entregaPreMontada, setEntregarPreMontada] = useState(true);
  const [usinagemKit, setUsinagemKit] = useState(true);
  const [folhaPorta, setFolhaPorta] = useState(null);
  const [marco, setMarco] = useState(null);
  const [precoCor, setPrecoCor] = useState(null);
  const [totalItem, setTotalItem] = useState(0);
  const [deveBuscarFolha, setDeveBuscarFolha] = useState(false);
  const [incluirPortaSemMontagem, setIncluirPortaSemMontagem] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [precoVistaFixa, setPrecoVistaFixa] = useState(null);
  const [precoVistaRegulavel, setPrecoVistaRegulavel] = useState(null);

  const [largurasVistaDisp, setLargurasVistaDisp] = useState([]);
  const nomePortaRef = useRef();
  const inputAlturaRef = useRef();
  const inputLarguraRef = useRef();
  const inputEspessuraRef = useRef();

  const validarPorta = () => {
    if (largura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a largura do vão"
      );
      inputLarguraRef.current.focus();
      return false;
    }
    if (altura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a altura do vão"
      );
      inputAlturaRef.current.focus();
      return false;
    }
    if (espessura == null) {
      toastr.warning(
        "Campo não informado",
        "Por favor, informe a espessura do vão da parede"
      );
      inputEspessuraRef.current.focus();
      return false;
    }
    if (folhaPorta == null) {
      toastr.warning(
        "Folha não encontrada",
        "Parece que o sistema não conseguiu definir a folha da porta. Por favor, revise os campos"
      );
      return false;
    }
    if (marco == null) {
      toastr.warning(
        "Marco não encontrado",
        "Parece que o sistema não conseguiu definir o marco. Por favor, revise os campos"
      );
      return false;
    }
    if (cor == null) {
      toastr.warning(
        "Cor de Acabamento não informada",
        "Por favor, selecione a cor de acabamento do kit"
      );
      return false;
    }
    if (larguraVista == null) {
      toastr.warning(
        "Vista não informada",
        "Por favor, selecione a largura da vista do kit"
      );
      return false;
    }
    if (dobradica == null) {
      toastr.warning(
        "Dobradiça não informada",
        "Por favor, selecione a dobradiça do kit"
      );
      return false;
    }
    if (ladoAbertura == null) {
      toastr.warning(
        "Lado de abertura não informado",
        "Por favor, selecione o lado de abertura"
      );
      return false;
    }

    return true;
  };

  const incluirPorta = async () => {
    if (!validarPorta()) return;

    let idPedidoVincularItem;
    if (idPedidoCab == null) {
      const retAbrirCab = await abrirCab();
      if (retAbrirCab) {
        idPedidoVincularItem = retAbrirCab;
        buscarItensPedido(idPedidoVincularItem);
      } else {
        return;
      }
    } else {
      idPedidoVincularItem = idPedidoCab;
    }

    setModalOpen(false);
    setIncluirPortaSemMontagem(true);

    const payload = {
      id_pedido: idPedidoVincularItem,
      descricao: nomePorta,
      vao_largura: largura,
      vao_altura: altura,
      vao_espessura: espessura,
      fol_largura_pronta: folhaPorta.largura_pronta,
      fol_altura_pronta: folhaPorta.altura_pronta,
      mar_largura_pronto: 0, // REVISAR
      mar_comprimento_pronto: 0, // REVISAR
      tipo_material: tipoMaterial,
      tipo_passagem: "ABRIR",
      tem_usinagem: usinagemKit,
      tem_montagem: entregaPreMontada,
      obs: "",
      id_dobradica: dobradica.value,
      id_fechadura: fechadura?.value ?? null,
      id_folha: folhaPorta.id,
      id_negativo: negativo?.value ?? null,
      id_caixa_trilho: null, // Utilizado na inclusão de Kits de Correr
      id_batente: null, // Utilizado na inclusão de Kits de Correr
      id_concha: null, // Utilizado na inclusão de Kits de Correr
      largura_vista: larguraVista,
      quantidades_vista: qtdVistas,
      lado_abrir: ladoAbertura,
      id_marco: marco.id,
      usinagens_especiais: usinagensEspeciais.map((it) => ({
        id_usinagem: it.value,
      })),
      qtd_itens_incluir: numPortasIncluir,
      markup: parseFloat(markup),
    };

    if (await apiPost("pedido/incluir_item/", payload)) {
      limparIncluirNovaPorta();
      nomePortaRef.current.focus();
      buscarItensPedido(idPedidoVincularItem);
    }
  };

  const recalcItem = () => {
    let totItemCalc =
      parseFloat(folhaPorta?.vlr_preco ?? "0") +
      parseFloat(marco?.vlr_preco ?? "0") +
      parseFloat(precoCor ?? "0") +
      parseFloat(dobradica?.vlr_preco ?? "0") *
        parseFloat(folhaPorta?.qtd_dobradicas ?? "0") +
      parseFloat(fechadura?.vlr_preco ?? "0") +
      parseFloat(negativo?.vlr_preco ?? "0") +
      parseFloat(larguraVista?.vlr_preco ?? "0");

    usinagensEspeciais.forEach((item) => {
      totItemCalc += parseFloat(item?.vlr_preco ?? "0");
    });

    if (usinagemKit) {
      totItemCalc += parseFloat(params.preco_usinagem ?? "0");
    }
    if (entregaPreMontada) {
      totItemCalc += parseFloat(params.preco_montagem ?? "0");

      totItemCalc += parseFloat(marco?.vlr_cantoneiras ?? "0");
    }

    setTotalItem(totItemCalc);
  };

  useEffect(() => {
    recalcItem();
  }, [
    folhaPorta,
    marco,
    precoCor,
    dobradica,
    fechadura,
    negativo,
    larguraVista,
    usinagensEspeciais,
    usinagemKit,
    entregaPreMontada,
  ]);

  const limparIncluirNovaPorta = () => {
    setAltura(null);
    inputAlturaRef.current.clear();
    setLargura(null);
    inputLarguraRef.current.clear();
    setEspessura(null);
    inputEspessuraRef.current.clear();
    setNomePorta("");
    nomePortaRef.current.focus();
    setFolhaPorta(null);
    setMarco(null);
    setEspessuraPorta(espessurasPorta[0].value);
    setLadoAbertura(ladosAbertura[0].value);
    setNumPortasIncluir(1);
  };

  useEffect(() => {
    if (!entregaPreMontada) {
      setIncluirPortaSemMontagem(false);
    }
  }, [entregaPreMontada]);

  const botaoIncluir = (
    <FormButton
      onClick={() => {
        if (!entregaPreMontada && !incluirPortaSemMontagem) {
          setModalOpen(true);
        } else {
          incluirPorta();
        }
      }}
      disabled={folhaPorta == null}
      disabledHint="A Folha da Porta deve ser encontrada"
    >
      Incluir Porta
    </FormButton>
  );

  const buscarFolha = useCallback(async () => {
    if (
      tipoMaterial != null &&
      altura != null &&
      largura != null &&
      espessura != null &&
      espessuraPorta != null &&
      cor != null &&
      deveBuscarFolha
    ) {
      if (parseFloat(largura) < 300) {
        inputLarguraRef.current.setInvalid("A largura mínima do vão é 300mm");
        return;
      }
      if (parseFloat(largura) > 1800) {
        inputLarguraRef.current.setInvalid("A largura máxima do vão é 1800mm");
        return;
      }
      if (parseFloat(altura) < 300) {
        inputAlturaRef.current.setInvalid("A altura mínima do vão é 300mm");
        return;
      }
      if (tipoMaterial === "PVC" && parseFloat(altura) > 2750) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas PVC é de 2750mm"
        );
        return;
      }
      if (tipoMaterial === "MDF" && parseFloat(altura) > 2650) {
        inputAlturaRef.current.setInvalid(
          "A altura máxima do vão em portas MDF é de 2650mm"
        );
        return;
      }

      const ret = await apiGet("/pedido/retorno_vao/", {
        mater: tipoMaterial,
        tipo_abre: "ABRIR",
        vao_altu: altura,
        vao_larg: largura,
        vao_espe: espessura,
        esp_folha: espessuraPorta,
        id_cor: cor.value,
        id_negativo: negativo?.value || null,
        largura_vista: larguraVista,
      });

      if (ret) {
        setFolhaPorta(ret.folha_porta);
        setMarco(ret.marco);
        setPrecoCor(ret?.preco_cor);
        setPrecoVistaFixa(ret.preco_vista_fixa);
        setPrecoVistaRegulavel(ret.preco_vista_regulavel);
      } else {
        setFolhaPorta(null);
        setMarco(null);
        setPrecoCor(null);
        setPrecoVistaFixa(null);
        setPrecoVistaRegulavel(null);
      }
    } else {
      setFolhaPorta(null);
      setMarco(null);
      setPrecoCor(null);
      setPrecoVistaFixa(null);
      setPrecoVistaRegulavel(null);
    }
    recalcItem();
  }, [
    tipoMaterial,
    espessuraPorta,
    altura,
    largura,
    espessura,
    cor,
    deveBuscarFolha,
    negativo,
    larguraVista,
  ]);

  useEffect(() => {
    buscarFolha();
  }, [buscarFolha]);

  useEffect(() => {
    recalcItem();
  }, [recalcItem]);

  useEffect(() => {
    if (!cor?.aceita_negativo) {
      setNegativo(null);
    }
  }, [cor]);

  const buscarLargurasVistaDisp = async () => {
    const params = {
      tipo_material: tipoMaterial,
      id_cor: cor.value,
    };
    const ret = await apiGet("cadastro/vista/listar_larguras/", params);

    setLargurasVistaDisp(ret);
  };

  useEffect(() => {
    if (cor && tipoMaterial) {
      buscarLargurasVistaDisp();
    } else {
      setLargurasVistaDisp([]);
      setLarguraVista(null);
    }
  }, [cor, tipoMaterial]);

  return (
    <>
      {modalOpen && (
        <ModalBase
          isOpen
          onConfirm={incluirPorta}
          title="Confirmação"
          size="sm"
          toggle={() => setModalOpen(!modalOpen)}
        >
          Você tem certeza que seja incluir a porta sem montagem do kit?
        </ModalBase>
      )}
      <div
        style={{
          display: "block",
          float: "right",
          marginLeft: "auto",
          padding: "10px",
          backgroundColor: "green",
          position: "absolute",
          right: "7px",
          color: "white",
          zIndex: 9999,
        }}
      >
        {folhaPorta && <p>Folha: {folhaPorta?.vlr_preco}</p>}
        {marco && <p>Marco: {marco?.vlr_preco}</p>}
        {marco && entregaPreMontada && (
          <p>
            Cantoneiras: {marco?.vlr_cantoneiras} ({marco?.qtd_cantoneira}x)
          </p>
        )}
        {precoCor && <p>Cor: {precoCor}</p>}
        {fechadura && <p>Fechadura: {fechadura?.vlr_preco}</p>}
        {dobradica && (
          <p>
            Dobradiça: {folhaPorta?.qtd_dobradicas ?? 0}x {dobradica?.vlr_preco}
          </p>
        )}
        {usinagensEspeciais.length > 0 && (
          <>
            {usinagensEspeciais.map((item) => (
              <p>{`${item.label}: ${item.vlr_preco}`}</p>
            ))}
          </>
        )}
        {qtdVistas == "1F1R" && (
          <p>
            Vista Fixa {precoVistaFixa}
            <br />
            Vista Regulável: {precoVistaRegulavel}
          </p>
        )}
        {qtdVistas == "2F" && <p>Vista Fixa {precoVistaFixa} x 2</p>}
        {qtdVistas == "2R" && <p>Vista Regulável: {precoVistaRegulavel} x 2</p>}
        {entregaPreMontada && <p>Montagem: {params?.preco_montagem}</p>}
        {usinagemKit && <p>Usinagem do Kit: {params?.preco_usinagem}</p>}

        <p>{`Total: ${totalItem}`}</p>
      </div>
      <Card body>
        <Row form className="pb-2">
          <TextInput
            md={8}
            label="Nome da Porta"
            value={nomePorta}
            onChange={setNomePorta}
            ref={nomePortaRef}
          />
        </Row>
        <Row form className="pb-2">
          <IntegerInput
            md={2}
            label={
              <span>
                Largura do <strong>VÃO</strong>
              </span>
            }
            onChange={(v) => {
              setLargura(v);
              setDeveBuscarFolha(false);
            }}
            placeholder="Em Milímetros"
            value={largura}
            onBlur={() => setDeveBuscarFolha(true)}
            ref={inputLarguraRef}
          />
          <FormLabel className="mr-4">{`${largura / 1000}m`}</FormLabel>
          <IntegerInput
            md={2}
            label={
              <span>
                Altura do <strong>VÃO</strong>
              </span>
            }
            onChange={(v) => {
              setAltura(v);
              setDeveBuscarFolha(false);
            }}
            placeholder="Em Milímetros"
            value={altura}
            onBlur={() => setDeveBuscarFolha(true)}
            ref={inputAlturaRef}
          />
          <FormLabel className="mr-4">{`${altura / 1000}m`}</FormLabel>
          <IntegerInput
            md={2}
            label={
              <span>
                Espessura da Parede do <strong>VÃO</strong>
              </span>
            }
            onChange={(v) => {
              setEspessura(v);
              setDeveBuscarFolha(false);
            }}
            placeholder="Em Milímetros"
            value={espessura}
            onBlur={() => setDeveBuscarFolha(true)}
            ref={inputEspessuraRef}
          />
          <FormLabel>{`${espessura / 10}cm`}</FormLabel>
          <div style={{ width: "27px" }}></div>
        </Row>
        <Row className="pb-2">
          <RadioGroup
            label="Espessura da Porta"
            options={espessurasPorta}
            value={espessuraPorta}
            onChange={setEspessuraPorta}
          />
          <div style={{ width: "27px" }}></div>
          <RadioGroup
            label="Lado de Abertura"
            options={ladosAbertura}
            value={ladoAbertura}
            onChange={setLadoAbertura}
          />
          {folhaPorta && (
            <>
              <div
                style={{
                  display: "block",
                  marginLeft: "27px",
                  marginRight: "27px",
                  fontSize: "12px",
                  fontStyle: "italic",
                  fontWeight: "bolder",
                }}
              >
                <Label style={{ display: "block", paddingBottom: "5px" }}>
                  Folha Bruta{" "}
                  {`A: ${parseFloat(folhaPorta.altura).toLocaleString("pt-br", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })} | L: ${parseFloat(folhaPorta.largura).toLocaleString(
                    "pt-br",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )} | E: ${parseFloat(folhaPorta.espessura).toLocaleString(
                    "pt-br",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}`}
                </Label>
                <Label style={{ display: "block" }}>
                  Folha Pronta{" "}
                  {`A: ${parseFloat(folhaPorta.altura_pronta).toLocaleString(
                    "pt-br",
                    { maximumFractionDigits: 0 }
                  )} | L: ${parseFloat(
                    folhaPorta.largura_pronta
                  ).toLocaleString("pt-br", { maximumFractionDigits: 0 })}`}
                </Label>
              </div>
            </>
          )}
          {marco && (
            <>
              <div
                style={{
                  display: "block",
                  marginLeft: "27px",
                  marginRight: "27px",
                  fontSize: "12px",
                  fontStyle: "italic",
                  fontWeight: "bolder",
                }}
              >
                <Label style={{ display: "block", paddingBottom: "5px" }}>
                  Marco{" "}
                  {`C: ${parseFloat(marco.comprimento).toLocaleString("pt-br", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })} | L: ${parseFloat(marco.espessura).toLocaleString(
                    "pt-br",
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                  )}`}
                </Label>
              </div>
            </>
          )}
          {qtdPortas > 0 && botaoIncluir}
        </Row>
      </Card>
      <Card body>
        <Row form className="pb-2">
          <AsyncComboBox
            label="Cor de Acabamento"
            isConcatField
            concatModelName="cor_acabamento"
            defaultOptions
            onChange={(_, cor) => setCor(cor)}
            md={2}
            className="mr-4"
            isClearable
          />
          <RadioGroup
            label="Vistas"
            options={organizaVistas}
            value={qtdVistas}
            onChange={setQtdVistas}
          />
          <ComboBox
            label="Vista"
            options={largurasVistaDisp}
            onChange={setLarguraVista}
            md={2}
            className="mr-4"
            value={larguraVista}
            isClearable
            isDisabled={largurasVistaDisp.length === 0}
          />
          {(cor?.aceita_negativo ?? false) && (
            <AsyncComboBox
              label="Negativo"
              isConcatField
              concatModelName="negativo"
              defaultOptions
              onChange={(_, negativo) => setNegativo(negativo)}
              md={2}
              className="mr-4"
              isClearable
              placeholder="Sem Negativo"
            />
          )}
        </Row>
        <Row form className="pb-2">
          <AsyncComboBox
            label="Fechadura"
            isConcatField
            concatModelName="fechadura"
            onChange={(_, fechadura) => setFechadura(fechadura)}
            md={5}
            aditional={{ modo_abert: "ABRIR" }}
            value={fechadura?.value}
            className="mr-4"
            isClearable
          />
          <AsyncComboBox
            label="Dobradiça"
            isConcatField
            concatModelName="dobradica"
            defaultOptions
            onChange={(_, dobradica) => setDobradica(dobradica)}
            md={5}
            isClearable
          />
        </Row>
        <Row>
          <AsyncComboBox
            label="Usinagens Especiais"
            concatModelName="usinagens_especiais"
            isConcatField
            defaultOptions
            isMulti
            onChange={setUsinagensEspeciais}
            md={4}
          />
          <FormCheckbox
            style={{ zIndex: 0 }}
            label="Entregar pré-montada"
            onChange={() => setEntregarPreMontada(!entregaPreMontada)}
            checked={entregaPreMontada}
          />
          <FormCheckbox
            style={{ zIndex: 0 }}
            label="Usinar o Kit"
            onChange={() => setUsinagemKit(!usinagemKit)}
            checked={usinagemKit}
          />
          {qtdPortas === 0 && botaoIncluir}
        </Row>
      </Card>
    </>
  );
}

export default IncluirKitPortasAbrir;
