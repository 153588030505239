import React, { useState, useCallback, useEffect } from "react";
import {
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../components";
import { Card, Row, Spinner } from "reactstrap";
import { apiGet, apiPut } from "../../../api";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

const locaisEntrega = [
  { label: "Eu Retiro na Ecoplac", value: "EU_RETIRO" },
  { label: "Ecoplac Entrega", value: "ECO_ENTREGA" },
  { label: "Transportadora", value: "TRANSP_RETIRA" },
];

const enderecosEntrega = [
  { label: "Meu Endereço", value: "END_USUARIO" },
  { label: "Outros", value: "OUTRO" },
];

export const ConfirmarPedido = () => {
  const history = useHistory();
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [dadosPedido, setDadosPedido] = useState({});
  const [informarCliente, setInformarCliente] = useState(false);
  const [localEntrega, setLocalEntrega] = useState(locaisEntrega[0].value);
  const [idCliente, setIdCliente] = useState(null);
  const [enderecoEntrega, setEnderecoEntrega] = useState(
    enderecosEntrega[0].value
  );
  const [logradouro, setLogradouro] = useState("");
  const [numeroEntrega, setNumeroEntrega] = useState("");
  const [complementoEntrega, setComplementoEntrega] = useState("");
  const [bairroEntrega, setBairroEntrega] = useState("");
  const [cidadeEntrega, setCidadeEntrega] = useState(null);
  const [nomeTransportadora, setNomeTransportadora] = useState("");
  const [vlrFrete, setVlrFrete] = useState(0);
  const [loading, setLoading] = useState(true);

  const buscarDadosPedido = useCallback(async () => {
    const dadosPed = await apiGet(
      `pedido/buscar_dados/${location.state.idPedido}/`
    );

    if (dadosPed) {
      setDadosPedido(dadosPed);
      setLoading(false);
    } else {
      toastr.error("Erro!", "Não foi possível encontrar os dados do pedido");
      history.goBack();
    }
  }, [location.state.idPedido]);

  useEffect(() => {
    buscarDadosPedido();
  }, [buscarDadosPedido]);

  const confirmarPedido = async () => {
    let locEntrega;
    if (localEntrega == "ECO_ENTREGA") {
      locEntrega = enderecoEntrega;
    } else {
      locEntrega = localEntrega;
    }
    const payload = {
      id_pedido: location.state.idPedido,
      id_cliente: idCliente,
      local_entrega: locEntrega,
      endereco: logradouro,
      numero: numeroEntrega,
      complemento: complementoEntrega,
      bairro: bairroEntrega,
      id_cidade: cidadeEntrega,
      nome_transportadora: nomeTransportadora ?? "",
      vlr_frete: vlrFrete ?? 0,
    };

    const ret = await apiPut("/pedido/concluir_pedido/", payload);
    console.log(ret);
    if (ret) {
      setRedirect(true);
    }
  };

  useEffect(() => {
    if (!informarCliente) {
      setIdCliente(null);
    }
  }, [informarCliente]);

  useEffect(() => {
    setVlrFrete(0);
  }, [localEntrega, enderecoEntrega]);

  const buscarCustoFrete = async () => {
    if (enderecoEntrega == "END_USUARIO" && localEntrega == "ECO_ENTREGA") {
      const ret = await apiGet(`cadastro/user/buscar_custo_frete/`);

      if (ret) {
        setVlrFrete(parseFloat(ret.custo_frete));
      }
    }
  };

  useEffect(() => {
    buscarCustoFrete();
  }, [enderecoEntrega, localEntrega]);

  const confirmarPedidoBtn = (
    <FormButton color="success" onClick={confirmarPedido}>
      Confirmar
    </FormButton>
  );

  return redirect ? (
    <Redirect to="/pedidos/" />
  ) : (
    <PageContainer title="Confirmar Pedido" number="0002" canGoBack>
      {loading ? (
        <Spinner
          style={{
            width: "3rem",
            height: "3rem",
            display: "flex",
            margin: "auto",
          }}
        />
      ) : (
        <>
          <Card body>
            <Row form>
              <TextInput
                md={5}
                label="Descrição do Pedido"
                disabled
                value={dadosPedido.descricao_ped}
              />
              <div style={{ marginLeft: "auto", display: "flex" }}>
                <NumberInput
                  label="Markup"
                  value={dadosPedido.markup_ped}
                  disabled
                />
                <NumberInput
                  label="Total do Pedido"
                  value={dadosPedido.vlr_total}
                  disabled
                />
              </div>
            </Row>
          </Card>
          <Card body>
            <Row form className="pb-2" style={{ zIndex: 999 }}>
              <FormCheckbox
                label="Informar o cliente"
                checked={informarCliente}
                onChange={() => setInformarCliente(!informarCliente)}
              />
              {informarCliente && (
                <AsyncComboBox
                  isConcatField
                  concatModelName="cliente"
                  label="Cliente"
                  isClearable
                  onChange={setIdCliente}
                />
              )}
            </Row>
            <Row>
              <NumberInput
                label="Valor de Frete"
                md={2}
                value={vlrFrete}
                onChange={setVlrFrete}
                disabled={localEntrega == "ECO_ENTREGA"}
              />
              <RadioGroup
                label="Dados de Entrega"
                options={locaisEntrega}
                value={localEntrega}
                onChange={setLocalEntrega}
              />
              {localEntrega == "ECO_ENTREGA" && (
                <RadioGroup
                  label="Endereço de Entrega"
                  options={enderecosEntrega}
                  value={enderecoEntrega}
                  onChange={setEnderecoEntrega}
                />
              )}
              {localEntrega == "TRANSP_RETIRA" && (
                <TextInput
                  label="Nome da Transportadora"
                  md={6}
                  value={nomeTransportadora}
                  onChange={setNomeTransportadora}
                />
              )}
              {enderecoEntrega != "OUTRO" && confirmarPedidoBtn}
            </Row>
            {enderecoEntrega == "OUTRO" && (
              <>
                <Row form className="pb-2">
                  <TextInput
                    label="Endereço de Entrega"
                    md={5}
                    onChange={setLogradouro}
                  />
                  <TextInput
                    label="Número"
                    md={1}
                    onChange={setNumeroEntrega}
                  />
                  <TextInput
                    label="Bairro"
                    md={2}
                    onChange={setBairroEntrega}
                  />
                </Row>
                <Row form>
                  <TextInput
                    label="Complemento"
                    md={5}
                    onChange={setComplementoEntrega}
                  />
                  <AsyncComboBox
                    isConcatField
                    concatModelName="cidade"
                    label="Cidade"
                    defaultOptions
                    onChange={(v, select) => {
                      setCidadeEntrega(v);
                      if (![null, undefined].includes(v)) {
                        setVlrFrete(parseFloat(select.vlr_frete ?? "0"));
                      }
                    }}
                  />
                  {confirmarPedidoBtn}
                </Row>
              </>
            )}
          </Card>
        </>
      )}
    </PageContainer>
  );
};
