import React, { useState } from "react";
import { Col, Label } from "reactstrap";

const FormLabel = ({ md = "auto", children, className }) => {
  return (
    <Col
      md={md}
      style={{ display: "flex", alignItems: "flex-end" }}
      className={className}
    >
      <Label className="form-label">{children}</Label>
    </Col>
  );
};

export default FormLabel;
