import React, { FC, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import logo from "../assets/img/logo/skillsoft_logo.png";
import { RootState } from "../redux/store/index.ts";
import routes, { RouteType } from "../routes/index.ts";

interface Props {
  username?: string;
}

interface MenuItemState {
  [path: string]: boolean;
}

const Sidebar: FC<Props> = ({ username }) => {
  const sidebar = useSelector((state: RootState) => state.sidebar);
  const location = useLocation();
  const [itensState, setItensState] = useState<MenuItemState>({});

  useEffect(() => {
    const pathName = location.pathname;

    routes.forEach((route) => {
      const isActive = pathName.indexOf(route.path) === 0;

      itensState[route.path] = isActive;
    });

    setItensState({ ...itensState });
  }, [location.pathname]);

  const handleClick = (path: string) => {
    itensState[path] = !itensState[path];
    setItensState({ ...itensState });
  };

  const getClassIsOpen = (path: string) => {
    return itensState[path];
  };

  const renderingHandler = (renderRoutes: RouteType[], level: number) => {
    return renderRoutes.filter((route) => !route?.hideFromMenu).map((route, index) => {
      const { name, badgeColor, badgeText, path, children, id } = route;
      if (children) {
        if (children.length > 0) {
          const isOpen = getClassIsOpen(path);
          return (
            <li
              className={`sidebar-item ${
                location.pathname.indexOf(path) ||
                (location.pathname === "/" && path === "/dashboard"
                  ? "active"
                  : "")
              }`}
              key={index}
            >
              <span
                data-toggle="collapse"
                className={`sidebar-link ${!isOpen ? "collapsed" : ""}`}
                onClick={() => handleClick(path)}
                aria-expanded={isOpen ? "true" : "false"}
              >
                <span className="align-middle">{name}</span>
                {badgeColor && badgeText ? (
                  <Badge color={badgeColor} size={18} className="sidebar-badge">
                    {badgeText}
                  </Badge>
                ) : null}
              </span>
              <Collapse isOpen={isOpen}>
                <ul id="item" className="sidebar-dropdown list-unstyled">
                  {renderingHandler(children, level + 1)}
                </ul>
              </Collapse>
            </li>
          );
        } else {
          return null;
        }
      } else {
        return (
          <li
            className={`sidebar-item ${
              location.pathname === path ? "active" : ""
            }`}
            key={id !== undefined ? id : index}
          >
            <NavLink
              to={path}
              className={`sidebar-link${level > 1 ? level : ""}`}
              activeClassName="active"
            >
              {/* {Icon ? <Icon size={18} className="align-middle mr-3" /> : null} */}
              {name}
              {badgeColor && badgeText ? (
                <Badge color={badgeColor} size={18} className="sidebar-badge">
                  {badgeText}
                </Badge>
              ) : null}
            </NavLink>
          </li>
        );
      }
    });
  };

  return (
    <nav
      className={`sidebar sidebar-sticky ${!sidebar.isOpen ? "" : "toggled"}`}
    >
      <PerfectScrollbar>
        <a className="sidebar-brand" href="/">
          <img
            className="mr-1"
            src={logo}
            alt="Skillsoft Sistemas"
            style={{ paddingRight: "12px", width: "50px", height: "auto" }}
          />
          <span className="align-middle">skillsoft</span>
        </a>
        <ul className="sidebar-nav">{renderingHandler(routes, 0)}</ul>
      </PerfectScrollbar>
    </nav>
  );
};

export default Sidebar;
