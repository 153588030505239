import { GerenciamentoPedidos } from "../../pages/pedidos/gerenciamento/GerenciamentoPedidos";
import { ConfirmarPedido } from "../../pages/pedidos/inclusao/ConfirmarPedido";
import { IncluirComponentes } from "../../pages/pedidos/inclusao/componentes/IncluirComponentes";
import { IncluirKitPortaAbrir } from "../../pages/pedidos/inclusao/IncluirKitPortaAbrir";
import { IncluirKitPortaCorrer } from "../../pages/pedidos/inclusao/IncluirKitPortaCorrer";

export const incluirKitPortaAbrirRoute = {
  path: "/pedidos/incluir_kit_abrir/",
  name: "Incluir Kit Porta - Abrir",
  component: IncluirKitPortaAbrir,
  hideFromMenu: true,
};

export const incluirKitPortaCorrerRoute = {
  path: "/pedidos/incluir_kit_correr/",
  name: "Incluir Kit Porta - Correr",
  component: IncluirKitPortaCorrer,
  hideFromMenu: true,
};

export const incluirComponentesRoute = {
  path: "/pedidos/incluir_componentes/",
  name: "Incluir Componentes",
  component: IncluirComponentes,
  hideFromMenu: true,
};

export const confirmarPedidoRoute = {
  path: "/pedidos/confirmar/",
  name: "Confirmar Pedido",
  component: ConfirmarPedido,
  hideFromMenu: true,
};

const pedidosRoutes = {
  path: "/pedidos/",
  name: "Pedidos",
  component: GerenciamentoPedidos,
};

export default pedidosRoutes;
