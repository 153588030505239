import React from "react";
import { TableCheck, TableDelete, TableEdit } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { Table } from "../../../../../components/Table";

const ComponentesGrid = ({ data, handleDeleteItem }) => {
  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "desc_tipo_componente",
      text: "Tipo",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "descricao_componente",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vao",
      text: "Tamanho",
      align: "center",
      headerAlign: "center",
      // A: Altura | L: Largura | E: Espessura
      formatter: (_, row) => {
        const alturaComp = formatNumber(row.altura_componente, true, 0, true);
        const larguraComp = formatNumber(row.largura_componente, true, 0, true);
        const espessuraComp = formatNumber(
          row.espessura_componente,
          true,
          0,
          true
        );
        if (row.tipo_componente === "FOLH") {
          return `A: ${alturaComp} L: ${larguraComp} E: ${espessuraComp}`;
        } else if (row.tipo_componente === "MARC") {
          return `A: ${alturaComp} E: ${espessuraComp} R: ${larguraComp}`;
        }
        return "";
      },
    },
    {
      dataField: "quantidade",
      text: "Quantidade",
      align: "center",
      headerAlign: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "vlr_item",
      text: "Valor Unitário",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act_delete",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete
          onClick={() => {
            if (handleDeleteItem) {
              handleDeleteItem(row.id);
            }
          }}
        />
      ),
    },
  ];

  return <Table data={data} columns={columns} paginated selectable={false} />;
};

export default ComponentesGrid;
