import React, { useState, useEffect } from "react";
import { LinkButton, NumberInput, PageContainer } from "../../../../components";
import {
  Card,
  Row,
  Spinner,
  Nav,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { apiGet, apiPost, apiDelete, apiPut } from "../../../../api";
import { useHistory } from "react-router-dom";
import ComponentesGrid from "./components/ComponentesGrid";
import IncluirFolhaPortaForm from "./components/IncluirFolhaPortaForm";
import classNames from "classnames";
import IncluirDobraduraForm from "./components/IncluirDobraduraForm";
import IncluirFechaduraForm from "./components/IncluirFechaduraForm";
import IncluirMarcoComRebaixoForm from "./components/IncluirMarcoComRebaixoForm";
import IncluirMarcoPassagemForm from "./components/IncluirMarcoPassagemForm";
import IncluirVistaForm from "./components/IncluirVistaForm";

export const IncluirComponentes = () => {
  const location = useLocation();
  const [idPedidoCab, setIdPedidoCab] = useState(null);
  const [loadingParams, setLoadingParams] = useState(true);
  const [totalPedido, setTotalPedido] = useState(0);
  const [itensPedido, setItensPedido] = useState([]);
  const [params, setParams] = useState(null);
  const history = useHistory();

  const [tipoComponente, setTipoComponente] = useState("DOBR");

  const buscarDadosPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/buscar_dados/${idPedido}/`);
    if (ret) {
      setTotalPedido(parseFloat(ret.vlr_total));
    }
  };

  const buscarItensPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/componentes/listar/${idPedido}/`);
    if (ret) {
      setItensPedido(ret.itens);
      setTotalPedido(parseFloat(ret.vlr_total_ped));
    }
  };

  const buscarDados = async () => {
    await buscarParametros();
    if (location.state?.idPedidoAlterar) {
      // Buscar dados do pedido para alteração
      setIdPedidoCab(location.state.idPedidoAlterar);
      await buscarDadosPedido(location.state.idPedidoAlterar);
      await buscarItensPedido(location.state.idPedidoAlterar);
    }
  };

  const buscarParametros = async () => {
    const ret = await apiGet("pedido/buscar_params/");

    if (ret) {
      setParams(ret);
      setLoadingParams(false);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const abrirCab = async () => {
    const payload = {
      tipo_pedido: "COMP",
      descricao_ped: "Pedido de Componentes",
      markup_ped: 0,
    };
    const ret = await apiPost("pedido/abrir_cab/", payload, {
      returnDataSuccess: true,
    });

    if (ret?.id_pedido) {
      setIdPedidoCab(ret.id_pedido);
      return ret.id_pedido;
    } else {
      return null;
    }
  };

  const handleDeleteItem = async (idItem) => {
    const ret = await apiDelete(`pedido/componentes/excluir/${idItem}/`);

    if (ret) {
      buscarItensPedido(idPedidoCab);
    }
  };

  return (
    <PageContainer title="Incluir Componentes" number="0002" canGoBack>
      {loadingParams ? (
        <Spinner
          style={{
            width: "3rem",
            height: "3rem",
            display: "flex",
            margin: "auto",
          }}
        />
      ) : (
        <>
          <Card body>
            <Row form>
              <div
                style={{
                  float: "right",
                  padding: "7px",
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <LinkButton
                  color="success"
                  md="auto"
                  pathname="/pedidos/confirmar/"
                  disabled={idPedidoCab == null}
                  state={{ idPedido: idPedidoCab }}
                >
                  Continuar Pedido
                </LinkButton>
              </div>
              <div
                style={{
                  float: "right",
                  backgroundColor: "#f8f8f8",
                  padding: "7px",
                }}
              >
                <NumberInput
                  label="Total do Pedido"
                  disabled
                  disabledBackground="#c1dec9"
                  value={totalPedido}
                />
              </div>
            </Row>
          </Card>
          <Card body>
            <Nav tabs>
              <NavLink
                className={classNames({ active: tipoComponente === "DOBR" })}
                onClick={() => setTipoComponente("DOBR")}
              >
                Dobradiça
              </NavLink>
              <NavLink
                className={classNames({ active: tipoComponente === "FOLH" })}
                onClick={() => setTipoComponente("FOLH")}
              >
                Folha de Porta
              </NavLink>
              <NavLink
                className={classNames({ active: tipoComponente === "FECH" })}
                onClick={() => setTipoComponente("FECH")}
              >
                Fechadura
              </NavLink>
              <NavLink
                className={classNames({ active: tipoComponente === "MRCR" })}
                onClick={() => setTipoComponente("MRCR")}
              >
                Marco Com Rebaixo
              </NavLink>
              <NavLink
                className={classNames({ active: tipoComponente === "MPSG" })}
                onClick={() => setTipoComponente("MPSG")}
              >
                Marco de Passagem
              </NavLink>
              <NavLink
                className={classNames({ active: tipoComponente === "VIST" })}
                onClick={() => setTipoComponente("VIST")}
              >
                Vista
              </NavLink>
            </Nav>
            <TabContent activeTab={tipoComponente} className="pt-2">
              <TabPane tabId={"DOBR"}>
                <IncluirDobraduraForm
                  abrirCab={abrirCab}
                  idPedidoCab={idPedidoCab}
                  buscarItensPedido={buscarItensPedido}
                />
              </TabPane>
              <TabPane tabId={"FECH"}>
                <IncluirFechaduraForm
                  abrirCab={abrirCab}
                  idPedidoCab={idPedidoCab}
                  buscarItensPedido={buscarItensPedido}
                />
              </TabPane>
              <TabPane tabId={"FOLH"}>
                <IncluirFolhaPortaForm
                  abrirCab={abrirCab}
                  idPedidoCab={idPedidoCab}
                  buscarItensPedido={buscarItensPedido}
                />
              </TabPane>
              <TabPane tabId={"MRCR"}>
                <IncluirMarcoComRebaixoForm
                  abrirCab={abrirCab}
                  idPedidoCab={idPedidoCab}
                  buscarItensPedido={buscarItensPedido}
                />
              </TabPane>
              <TabPane tabId={"MPSG"}>
                <IncluirMarcoPassagemForm
                  abrirCab={abrirCab}
                  idPedidoCab={idPedidoCab}
                  buscarItensPedido={buscarItensPedido}
                />
              </TabPane>
              <TabPane tabId={"VIST"}>
                <IncluirVistaForm
                  abrirCab={abrirCab}
                  idPedidoCab={idPedidoCab}
                  buscarItensPedido={buscarItensPedido}
                />
              </TabPane>
            </TabContent>
          </Card>
          <Card body>
            <ComponentesGrid
              data={itensPedido}
              handleDeleteItem={handleDeleteItem}
            />
          </Card>
        </>
      )}
    </PageContainer>
  );
};
