import moment from "moment";
import React, { useRef, useState } from "react";
import { toastr } from "react-redux-toastr";
import { isValidDate } from "../coreUtils";
import AsyncComboBox from "./AsyncComboBox";
import { DatePicker } from "./DatePicker";

// NÃO USAR esta constante na função tirarDia
const hoje = new Date();

const mesAtu = hoje.getMonth();

const anoAtu = hoje.getFullYear();

const primDiaMesAnt = new Date(anoAtu, mesAtu - 1, 1);
const ultDiaMesAnt = new Date(anoAtu, mesAtu, 0);

const primDiaMesAtu = new Date(anoAtu, mesAtu, 1);
const ultDiaMesAtu = new Date(anoAtu, mesAtu + 1, 0);

const primDiaProxMes = new Date(anoAtu, mesAtu + 1, 1);
const ultDiaProxMes = new Date(anoAtu, mesAtu + 2, 0);

export const FiltroPeriodoDatas = ({
  onChange,
  label = "Período",
  defaultOption = "HOJE",
  defaultStart = new Date(),
  defaultEnd = new Date(),
  minStart,
  maxEnd,
}) => {
  const [dataIniInt, setDataIniInt] = useState(defaultStart);
  const [dataFimInt, setDataFimInt] = useState(defaultEnd);
  const selectRef = useRef();

  const dataFimMenosDias = (dias) => {
    let df = new Date(dataFimInt.toISOString());

    df.setDate(df.getDate() - dias);

    return df;
  };

  const options = [
    {
      label: "Hoje",
      value: "HOJE",
    },
    {
      label: "Ontem",
      value: "ONTEM",
      isDisabled:
        minStart instanceof Date &&
        dataFimInt instanceof Date &&
        minStart.getTime() > dataFimMenosDias(1),
    },
    {
      label: "3 Dias",
      value: "3D",
      isDisabled:
        minStart instanceof Date &&
        dataFimInt instanceof Date &&
        minStart.getTime() > dataFimMenosDias(3),
    },
    {
      label: "7 Dias",
      value: "7D",
      isDisabled:
        minStart instanceof Date &&
        dataFimInt instanceof Date &&
        minStart.getTime() > dataFimMenosDias(7),
    },
    {
      label: "15 Dias",
      value: "15D",
      isDisabled:
        minStart instanceof Date &&
        dataFimInt instanceof Date &&
        minStart.getTime() > dataFimMenosDias(15),
    },
    {
      label: "30 Dias",
      value: "30D",
      isDisabled:
        minStart instanceof Date &&
        dataFimInt instanceof Date &&
        minStart.getTime() > dataFimMenosDias(30),
    },
    {
      label: "Mês Atual",
      value: "MESATU",
      isDisabled:
        (maxEnd instanceof Date &&
          maxEnd.getTime() < primDiaMesAtu.getTime()) ||
        (minStart instanceof Date &&
          minStart.getTime() > ultDiaMesAtu.getTime()),
    },
    {
      label: "Mês Anterior",
      value: "MESANT",
      isDisabled:
        (maxEnd instanceof Date &&
          maxEnd.getTime() < primDiaMesAnt.getTime()) ||
        (minStart instanceof Date &&
          minStart.getTime() > ultDiaMesAnt.getTime()),
    },
    {
      label: "Mês Anterior + Atual",
      value: "MESATUANT",
      isDisabled:
        (maxEnd instanceof Date &&
          maxEnd.getTime() < primDiaMesAnt.getTime()) ||
        (minStart instanceof Date &&
          minStart.getTime() > ultDiaMesAtu.getTime()),
    },
    {
      label: "Próximo Mês",
      value: "PROXMES",
      isDisabled:
        (maxEnd instanceof Date &&
          maxEnd.getTime() < primDiaProxMes.getTime()) ||
        (minStart instanceof Date &&
          minStart.getTime() > ultDiaProxMes.getTime()),
    },
  ];

  const handleDataIni = (value) => {
    var _dataIni = moment.isMoment(value) ? value.toDate() : value;
    selectRef.current.clearValue();
    setDataIniInt(_dataIni);
    onChange(_dataIni, dataFimInt);
  };

  const handleDataFim = (value) => {
    var _dataFim = moment.isMoment(value) ? value.toDate() : value;
    selectRef.current.clearValue();
    setDataFimInt(_dataFim);
    onChange(dataIniInt, _dataFim);
  };

  const handleSelect = (value) => {
    if (
      !(dataFimInt instanceof Date) &&
      ["3D", "7D", "15D", "30D"].includes(value)
    ) {
      toastr.warning("Atenção", "Por favor, revise a data de fim.");
      return;
    }
    let _dataFim = dataFimInt;
    let _dataIni =
      dataFimInt instanceof Date ? new Date(dataFimInt.getTime()) : new Date();

    switch (value) {
      case "HOJE":
        _dataIni = new Date();
        _dataFim = new Date();
        break;
      case "ONTEM":
        let ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);

        _dataIni = ontem;
        _dataFim = ontem;
        break;
      case "3D":
        _dataIni = dataFimMenosDias(3);
        break;
      case "7D":
        _dataIni = dataFimMenosDias(7);
        break;
      case "15D":
        _dataIni = dataFimMenosDias(15);
        break;
      case "30D":
        _dataIni = dataFimMenosDias(30);
        break;
      case "MESATU":
        _dataFim = ultDiaMesAtu;
        _dataIni = primDiaMesAtu;
        break;
      case "MESANT":
        _dataFim = ultDiaMesAnt;
        _dataIni = primDiaMesAnt;
        break;
      case "MESATUANT":
        _dataFim = ultDiaMesAtu;
        _dataIni = primDiaMesAnt;
        break;
      case "PROXMES":
        _dataFim = ultDiaProxMes;
        _dataIni = primDiaProxMes;
        break;
      default:
        return;
    }

    if (!isValidDate(_dataIni)) {
      _dataIni = null;
    } else {
      if (minStart instanceof Date && _dataIni.getTime() < minStart.getTime()) {
        _dataIni = new Date(minStart.toISOString());
      }
    }

    if (!isValidDate(_dataFim)) {
      _dataFim = null;
    } else {
      if (maxEnd instanceof Date && _dataFim.getTime() > maxEnd.getTime()) {
        _dataFim = new Date(maxEnd.toISOString());
      }
    }

    setDataFimInt(_dataFim);
    setDataIniInt(_dataIni);
    onChange(_dataIni, _dataFim);
  };

  return (
    <React.Fragment>
      <AsyncComboBox
        md={3}
        defaultOptions={options}
        label={label}
        name="periodo"
        defaultValue={defaultOption}
        onChange={(v) => handleSelect(v?.value)}
        ref={selectRef}
        isSearchable={false}
      />
      <DatePicker
        label="Início"
        name="data_ini"
        value={dataIniInt}
        md={2}
        onChange={handleDataIni}
        inputId="in-data-ini"
        minDate={minStart}
        maxDate={maxEnd}
      />
      <DatePicker
        label="Fim"
        name="data_fim"
        md={2}
        value={dataFimInt}
        onChange={handleDataFim}
        inputId="in-data-fim"
        minDate={minStart}
        maxDate={maxEnd}
      />
    </React.Fragment>
  );
};
