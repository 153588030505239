import React from "react";
import { useState } from "react";
import {
  AsyncComboBox,
  FormCheckbox,
  MaskedInput,
  ModalCadastro,
  TextInput,
} from "../../../components";
import { Row } from "reactstrap";
import { MODAL_ACTIONS } from "../../../coreUtils";

export const CadastroClienteModal = ({ selected, notifyEvent }) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [fone1, setFone1] = useState("");
  const [fone2, setFone2] = useState("");
  const [fone3, setFone3] = useState("");
  const [endereco, setEndereco] = useState("");
  const [nroEnder, setNroEnder] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [idCidade, setIdCidade] = useState(0);
  const [pesFisica, setPesFisica] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [razaoSoc, setRazaoSoc] = useState("");
  const [curinga, setCuringa] = useState(false);
  const [email, setEmail] = useState("");
  const [obs, setObs] = useState("");

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setFone1("");
    setFone2("");
    setFone3("");
    setEndereco("");
    setNroEnder("");
    setComplemento("");
    setBairro("");
    setIdCidade(0);
    setPesFisica(false);
    setCpfCnpj("");
    setRazaoSoc("");
    setCuringa(false);
    setEmail("");
    setObs("");
  };

  const handleSetPessoaFisica = (v) => {
    setPesFisica(v);
    setCpfCnpj("");
    if (!v) setRazaoSoc("");
  };

  const fetchData = (data) => {
    setNome(data.nome);
    setFone1(data.fone1);
    setFone2(data.fone2);
    setFone3(data.fone3);
    setEndereco(data.endereco);
    setNroEnder(data.nro_ender);
    setComplemento(data.complemento);
    setBairro(data.bairro);
    setIdCidade(data.id_cidade);
    setPesFisica(data.pes_fisica);
    setCpfCnpj(data.cpf_cnpj);
    setCuringa(data.curinga);
    setEmail(data.email);
    setObs(data.obs);
    setAtivo(data.ativo);

    if (!data.pes_fisica) {
      setRazaoSoc("");
    } else {
      setRazaoSoc(data.razao_soc);
    }
  };

  const submitPayload = (action) => {
    const payload = {
      nome: nome,
      fone1: fone1,
      fone2: fone2,
      fone3: fone3,
      endereco: endereco,
      nro_ender: nroEnder,
      complemento: complemento,
      bairro: bairro,
      id_cidade: idCidade,
      pes_fisica: pesFisica,
      cpf_cnpj: cpfCnpj,
      razao_soc: razaoSoc,
      curinga: curinga,
      email: email,
      obs: obs,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  return (
    <ModalCadastro
      title="Cliente"
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase="/cadastro/cliente"
      number="0003_1"
      deleteModalNumber="0003_2"
      selected={selected}
      notifyEvent={notifyEvent}
    >
      <Row>
        <TextInput
          label="Nome"
          value={nome}
          onChange={setNome}
          maxLength={100}
        />
      </Row>
      <Row>
        <TextInput
          md={6}
          label="Endereço"
          value={endereco}
          onChange={setEndereco}
          maxLength={60}
        />
        <TextInput
          md={2}
          label="Número"
          value={nroEnder}
          onChange={setNroEnder}
          maxLength={15}
        />
        <TextInput
          md={4}
          label="Complemento"
          value={complemento}
          onChange={setComplemento}
          maxLength={40}
        />
      </Row>
      <Row>
        <TextInput
          label="Bairro"
          value={bairro}
          onChange={setBairro}
          maxLength={40}
        />
        <AsyncComboBox
          md={6}
          label="Cidade"
          isConcatField
          concatModelName="cidade"
          value={idCidade}
          onChange={setIdCidade}
        />
      </Row>
      <Row>
        <TextInput
          label="Fone 1"
          value={fone1}
          onChange={setFone1}
          maxLength={18}
        />
        <TextInput
          label="Fone 2"
          value={fone2}
          onChange={setFone2}
          maxLength={18}
        />
        <TextInput
          label="Fone 3"
          value={fone3}
          onChange={setFone3}
          maxLength={18}
        />
      </Row>
      <Row>
        <FormCheckbox
          md={2}
          label="Pessoa Física"
          checked={pesFisica}
          onChange={handleSetPessoaFisica}
        />
        <MaskedInput
          mask={pesFisica ? "999.999.999-99" : "99.999.999/9999-99"}
          name="cpf_cnpj"
          label={pesFisica ? "CPF" : "CNPJ"}
          md={3}
          value={cpfCnpj}
          onChange={setCpfCnpj}
        />
        {!pesFisica && (
          <TextInput
            md={7}
            label="Razão Social"
            value={razaoSoc}
            onChange={setRazaoSoc}
            maxLength={80}
          />
        )}
      </Row>
      <Row>
        <FormCheckbox label="Curinga" checked={curinga} onChange={setCuringa} />
        <TextInput
          md={6}
          label="E-Mail"
          value={email}
          onChange={setEmail}
          maxLength={80}
        />
      </Row>
      <Row>
        <TextInput
          label="Observação"
          type="textarea"
          rows={3}
          value={obs}
          onChange={setObs}
        />
      </Row>
    </ModalCadastro>
  );
};
