import React, { useState, useCallback, useEffect } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  ComboBox,
  FormButton,
  IntegerInput,
  NumberInput,
} from "../../../../../components";
import { apiGet, apiPost } from "../../../../../api";
import { Table } from "../../../../../components/Table";
import { formatNumber } from "../../../../../coreUtils";

const rebaixosMarco = [
  { label: "36mm", value: 36 },
  { label: "41mm", value: 41 },
];

const tiposMaterial = [
  { label: "MDF", value: "MDF" },
  { label: "PVC", value: "PVC" },
];

const largurasMarcos = [
  { label: "70mm", value: 70 },
  { label: "90mm", value: 90 },
  { label: "115mm", value: 115 },
  { label: "140mm", value: 140 },
  { label: "160mm", value: 160 },
  { label: "190mm", value: 190 },
  { label: "230mm", value: 230 },
];

const IncluirMarcoComRebaixoForm = ({
  abrirCab,
  idPedidoCab,
  buscarItensPedido,
}) => {
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);
  const [rebaixoMarco, setRebaixoMarco] = useState(rebaixosMarco[0].value);
  const [idCor, setIdCor] = useState(null);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [larguraMarco, setLarguraMarco] = useState(null);
  const [comprimentoMarco, setComprimentoMarco] = useState(null);
  const [comprimentosMarcos, setComprimentosMarcos] = useState([]);
  const [marcos, setMarcos] = useState([]);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const columnsGrid = [
    {
      dataField: "descricao_material",
      text: "Material",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "id_cor__descricao",
      text: "Cor",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "comprimento",
      text: "Comprimento",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 0),
    },
    {
      dataField: "espessura",
      text: "Largura",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 0),
      sort: true,
    },
    {
      dataField: "rebaixo",
      text: "Rebaixo",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 0),
      sort: true,
    },
    {
      dataField: "vlr_preco",
      text: "Preço",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
  ];

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
    setTipoMaterial(tiposMaterial[0].value);
    setIdCor(null);
    setLarguraMarco(null);
    setComprimentoMarco(null);
  };

  const incluirComponente = async () => {
    setLoadingIncluirComponente(true);
    try {
      let idPedidoVincularItem;
      if (idPedidoCab == null) {
        const retAbrirCab = await abrirCab();
        if (retAbrirCab) {
          idPedidoVincularItem = retAbrirCab;
        } else {
          return;
        }
      } else {
        idPedidoVincularItem = idPedidoCab;
      }

      const payload = {
        id_pedido: idPedidoVincularItem,
        tipo_componente: "MARC",
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_item: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
      };

      if (await apiPost("pedido/componentes/incluir/", payload)) {
        limparIncluirNovoComponente();
        buscarItensPedido(idPedidoVincularItem);
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  const buscarMarcos = useCallback(async () => {
    if (tipoMaterial != null && rebaixoMarco && idCor != null) {
      const ret = await apiGet("/cadastro/marco/listar/", {
        tipo_material: tipoMaterial,
        rebaixo: rebaixoMarco,
        id_cor: idCor,
        comprimento: comprimentoMarco,
        largura: larguraMarco,
        tipo_marco: "NORM",
      });
      setMarcos(ret ?? []);
    } else {
      setMarcos([]);
      setVlrUnitComponente(0);
      setIdComponente(null);
    }
  }, [tipoMaterial, rebaixoMarco, idCor, comprimentoMarco]);

  useEffect(() => {
    buscarMarcos();
  }, [buscarMarcos]);

  const buscarComprimentos = async () => {
    const ret = await apiGet("/cadastro/marco/listar_comprimentos/");
    setComprimentosMarcos(ret || []);
  };

  useEffect(() => {
    buscarComprimentos();
  }, []);

  return (
    <>
      <Row>
        <AsyncComboBox
          label="Cor"
          isConcatField
          concatModelName={"cor_acabamento"}
          defaultOptions
          onChange={(_, selected) => {
            setIdCor(selected?.value);
          }}
          md={2}
          isClearable
          value={idCor}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Tipo de Material"
          options={tiposMaterial}
          value={tipoMaterial}
          onChange={setTipoMaterial}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Rebaixo"
          options={rebaixosMarco}
          value={rebaixoMarco}
          onChange={setRebaixoMarco}
          isSearchable={false}
        />
        <ComboBox
          md={2}
          label="Comprimento do Marco"
          options={comprimentosMarcos}
          value={comprimentoMarco}
          onChange={setComprimentoMarco}
          isSearchable
          isClearable
        />
        <ComboBox
          md={2}
          label="Largura do Marco"
          options={largurasMarcos}
          value={larguraMarco}
          onChange={setLarguraMarco}
          isSearchable
          isClearable
        />
      </Row>
      <div className="mt-4 mb-4">
        <Table
          columns={columnsGrid}
          data={marcos}
          paginated={false}
          pageSize={15}
          onSelect={(v, checked, row) => {
            if (checked) {
              setIdComponente(v);
              setVlrUnitComponente(parseFloat(row.vlr_preco));
            } else {
              setIdComponente(null);
              setVlrUnitComponente(0);
            }
          }}
        />
      </div>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluirComponente}
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirMarcoComRebaixoForm;
