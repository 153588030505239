import React, { useState, useCallback, useEffect } from "react";
import {
  FormButton,
  LinkButton,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../components";
import { PortasPedidoGrid } from "./components/PortasPedidoGrid";
import { Card, Row, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import { apiGet, apiPost, apiDelete, apiPut } from "../../../api";
import { useHistory } from "react-router-dom";
import IncluirKitPortasAbrir from "./components/IncluirKitPortasAbrir";

const tiposMaterial = [
  { label: "MDF", value: "MDF" },
  { label: "PVC", value: "PVC" },
];

export const IncluirKitPortaAbrir = () => {
  const location = useLocation();
  const [idPedidoCab, setIdPedidoCab] = useState(null);
  const [descricaoPed, setDescricaoPed] = useState(null);
  const [loadingParams, setLoadingParams] = useState(true);
  const [tipoMaterial, setTipoMaterial] = useState(tiposMaterial[0].value);
  const [portasPedido, setPortasPedido] = useState([]);
  const [markup, setMarkup] = useState(0);
  const [totalPedido, setTotalPedido] = useState(0);
  const [params, setParams] = useState(null);
  const [recalcularMarkup, setRecalcularMarkup] = useState(false);
  const history = useHistory();

  const buscarDadosPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/buscar_dados/${idPedido}/`);
    if (ret) {
      setDescricaoPed(ret.descricao_ped);
      setMarkup(ret.markup_ped);
      setPortasPedido(ret.itens);
      setTotalPedido(parseFloat(ret.vlr_total));
    }
  };

  const buscarItensPedido = async (idPedido) => {
    const ret = await apiGet(`pedido/listar_itens/${idPedido}/`);
    if (ret) {
      setTotalPedido(ret.tot_pedido);
      setPortasPedido(ret.itens);
    }
  };

  const buscarDados = async () => {
    await buscarParametros();
    if (location.state?.idPedidoAlterar) {
      // Buscar dados do pedido para alteração
      setIdPedidoCab(location.state.idPedidoAlterar);
      await buscarDadosPedido(location.state.idPedidoAlterar);
    }
  };

  const buscarParametros = async () => {
    const ret = await apiGet("pedido/buscar_params/");

    if (ret) {
      setParams(ret);
      setMarkup(ret.markup_pad);
      setLoadingParams(false);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const abrirCab = async () => {
    const payload = {
      tipo_pedido: "KABR",
      descricao_ped: descricaoPed ?? "",
      markup_ped: parseFloat(markup),
    };
    const ret = await apiPost("pedido/abrir_cab/", payload, {
      returnDataSuccess: true,
    });

    if (ret?.id_pedido) {
      setIdPedidoCab(ret.id_pedido);
      return ret.id_pedido;
    } else {
      return null;
    }
  };

  const handleDeleteItem = async (idItem) => {
    const ret = await apiDelete(`pedido/excluir_item/${idItem}/`);

    if (ret) {
      buscarItensPedido(idPedidoCab);
    }
  };

  const handleMarkupAlterado = async () => {
    if (recalcularMarkup && idPedidoCab) {
      const ret = await apiPut(`pedido/recalcular_markup/`, {
        id_pedido: idPedidoCab,
        markup_novo: parseFloat(markup),
      });

      if (ret) {
        setRecalcularMarkup(false);
        buscarItensPedido(idPedidoCab);
      }
    }
  };

  return (
    <PageContainer title="Incluir Kit Porta - Abrir" number="0002" canGoBack>
      {loadingParams ? (
        <Spinner
          style={{
            width: "3rem",
            height: "3rem",
            display: "flex",
            margin: "auto",
          }}
        />
      ) : (
        <>
          <Card body>
            <Row form>
              <TextInput
                autoFocus
                md={5}
                label="Descrição do Pedido"
                onChange={setDescricaoPed}
                value={descricaoPed}
              />
              <RadioGroup
                label="Tipo de Material"
                options={tiposMaterial}
                value={tipoMaterial}
                onChange={setTipoMaterial}
              />
              <NumberInput
                label="Markup"
                decimalPlaces={2}
                value={markup}
                onChange={(v) => {
                  if (v != markup) {
                    setRecalcularMarkup(true);
                    setMarkup(v);
                  }
                }}
                onBlur={handleMarkupAlterado}
                md={1}
              />
              <div
                style={{
                  float: "right",
                  padding: "7px",
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <LinkButton
                  color="success"
                  md="auto"
                  pathname="/pedidos/confirmar/"
                  disabled={idPedidoCab == null}
                  state={{ idPedido: idPedidoCab }}
                >
                  Continuar Pedido
                </LinkButton>
              </div>
              <div
                style={{
                  float: "right",
                  backgroundColor: "#f8f8f8",
                  padding: "7px",
                }}
              >
                <NumberInput
                  label="Total do Pedido"
                  disabled
                  disabledBackground="#c1dec9"
                  value={totalPedido}
                />
              </div>
            </Row>
          </Card>
          <IncluirKitPortasAbrir
            idPedidoCab={idPedidoCab}
            abrirCab={abrirCab}
            params={params}
            tipoMaterial={tipoMaterial}
            numItensPed={portasPedido.length}
            buscarItensPedido={buscarItensPedido}
            markup={markup}
            qtdPortas={portasPedido.length}
          />
          <Card body>
            <PortasPedidoGrid
              data={portasPedido}
              handleDeleteItem={handleDeleteItem}
            />
          </Card>
        </>
      )}
    </PageContainer>
  );
};
