import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Search } from "react-feather";
import {
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import { handleFocus } from "../coreUtils";

const SearchInput = forwardRef(
  (
    {
      md,
      type = "text",
      name,
      onChange,
      value,
      className,
      loading,
      placeholder = "Pesquisar",
      divClassName,
      hideLabel,
      autoFocus,
      onKeyDown = handleFocus,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState("");
    const inputRef = useRef("");

    useEffect(() => setInternalValue(value), [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      const inputValue = (e.target.value || "").toUpperCase();
      setInternalValue(inputValue);
      if (onChange) {
        onChange(inputValue);
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: internalValue?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    return (
      <Col md={md} className={divClassName}>
        {!hideLabel && <Label>Pesquisa</Label>}
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {loading ? <Spinner size="sm" /> : <Search size={15} />}
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type={type}
            name={name}
            onChange={handleOnChange}
            defaultValue={value}
            innerRef={inputRef}
            className={className}
            value={internalValue}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
          />
        </InputGroup>
      </Col>
    );
  }
);

export default SearchInput;
