import Cookies from "js-cookie";
import { useState } from "react";
import { singletonHook } from "react-singleton-hook";
import { revokeToken } from "../api";

const initialState = {
  accessToken: Cookies.get("access-token"),
  refreshToken: Cookies.get("refresh-token"),
};
let configureSessionGlobal = () => {
  throw new Error("Você deve chamar useSession antes de logar.");
};

const useSessionImpl = () => {
  const [session, setSession] = useState(initialState);
  configureSessionGlobal = setSession;
  return session;
};

export const useSession = singletonHook(initialState, useSessionImpl);

export const login = (accessToken, refreshToken) => {
  Cookies.set("access-token", accessToken);
  Cookies.set("refresh-token", refreshToken);
  configureSessionGlobal({ accessToken, refreshToken });
};

export const logoff = async () => {
  await revokeToken();
  Cookies.remove("access-token");
  Cookies.remove("refresh-token");
  configureSessionGlobal({
    accessToken: "",
    refreshToken: "",
  });
};
