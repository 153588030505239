import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { handleFocus } from "../coreUtils";

const TableMaskedInput = forwardRef(
  (
    {
      type = "text",
      name,
      onChange,
      value,
      className,
      tabOrder,
      id,
      inputStyle,
      onBlur,
      autoFocus,
      size,
      mask,
      maskChar = null,
      maskPlaceholder,
      forceUpperCase,
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState("");
    const [callOnBlur, setCallOnBlur] = useState(false);
    const inputRef = useRef("");
    const [internalId] = useState(
      id ?? "text-input-" + Math.floor(Math.random() * Date.now())
    );

    useEffect(() => {
      if (value?.toUpperCase() !== internalValue?.toUpperCase()) {
        if (forceUpperCase) {
          setInternalValue(value?.toUpperCase());
        } else {
          setInternalValue(value);
        }
      }
    }, [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      let inputValue = e.target.value || "";
      setInternalValue(inputValue);
      if (onChange) {
        setCallOnBlur(true);
        if (forceUpperCase) inputValue = inputValue.toUpperCase();
        onChange(e, inputValue?.toUpperCase());
      }
    };

    const onKeyDown = (e) => {
      if (type !== "textarea" || (e.key !== "Enter" && e.key !== "ArrowUp")) {
        handleFocus(e);
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        let inputValue = e.target.value || "";
        if (forceUpperCase) inputValue = inputValue.toUpperCase();
        if (callOnBlur) onBlur(e, inputValue);
      }
      setCallOnBlur(false);
    };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: forceUpperCase
        ? internalValue?.toUpperCase()?.trim()
        : internalValue?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    return (
      <InputMask
        mask={mask}
        onChange={handleOnChange}
        value={internalValue}
        maskPlaceholder={maskPlaceholder}
        maskChar={maskChar}
        alwaysShowMask={false}
        onBlur={onBlurInternal}
      >
        {() => (
          <input
            id={internalId}
            autoFocus={autoFocus}
            type={type}
            name={name}
            onChange={handleOnChange}
            innerRef={inputRef}
            className={className}
            value={internalValue}
            onKeyDown={onKeyDown}
            tabOrder={tabOrder}
            style={{
              textTransform: forceUpperCase ? "uppercase" : "none",
              ...inputStyle,
            }}
            size={size}
          />
        )}
      </InputMask>
    );
  }
);

export default TableMaskedInput;
