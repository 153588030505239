import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  IntegerInput,
  NumberInput,
} from "../../../../../components";
import { apiPost } from "../../../../../api";

const IncluirDobraduraForm = ({ abrirCab, idPedidoCab, buscarItensPedido }) => {
  const [idComponente, setIdComponente] = useState(null);
  const [descricaoComponente, setDescricaoComponente] = useState(null);
  const [vlrUnitComponente, setVlrUnitComponente] = useState(0);
  const [quantidadeComponente, setQuantidadeComponente] = useState(1);

  const [loadingIncluirComponente, setLoadingIncluirComponente] =
    useState(false);

  const limparIncluirNovoComponente = () => {
    setIdComponente(null);
    setDescricaoComponente(null);
    setVlrUnitComponente(0);
    setQuantidadeComponente(1);
  };

  const incluirComponente = async () => {
    setLoadingIncluirComponente(true);
    try {
      let idPedidoVincularItem;
      if (idPedidoCab == null) {
        const retAbrirCab = await abrirCab();
        if (retAbrirCab) {
          idPedidoVincularItem = retAbrirCab;
        } else {
          return;
        }
      } else {
        idPedidoVincularItem = idPedidoCab;
      }

      const payload = {
        id_pedido: idPedidoVincularItem,
        tipo_componente: "DOBR",
        id_componente: idComponente,
        descricao_componente: descricaoComponente ?? "",
        quantidade: quantidadeComponente,
        vlr_item: vlrUnitComponente,
        vlr_total: quantidadeComponente * vlrUnitComponente,
        obs: "",
      };

      if (await apiPost("pedido/componentes/incluir/", payload)) {
        limparIncluirNovoComponente();
        buscarItensPedido(idPedidoVincularItem);
      }
    } finally {
      setLoadingIncluirComponente(false);
    }
  };

  return (
    <>
      <Row>
        <AsyncComboBox
          label="Selecione a Dobradiça"
          isConcatField
          concatModelName="dobradica"
          defaultOptions
          onChange={(_, selected) => {
            setIdComponente(selected?.value);
            setDescricaoComponente(selected?.label);
            setVlrUnitComponente(parseFloat(selected?.vlr_preco ?? "0"));
          }}
          md={6}
          className="mr-4"
          isClearable
          value={idComponente}
        />
      </Row>
      <Row>
        <IntegerInput
          md={2}
          label="Quantidade"
          onChange={setQuantidadeComponente}
          value={quantidadeComponente}
        />
        <NumberInput
          md={2}
          label="Valor Unitário"
          disabled
          value={vlrUnitComponente}
        />
        <NumberInput
          md={2}
          label="Total do Item"
          disabled
          value={(quantidadeComponente ?? 0) * (vlrUnitComponente ?? 0)}
        />
        <FormButton
          md="auto"
          loading={loadingIncluirComponente}
          onClick={incluirComponente}
          color="info"
        >
          Incluir Componente
        </FormButton>
      </Row>
    </>
  );
};

export default IncluirDobraduraForm;
