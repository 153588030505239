import React from "react";
import { DataTable } from "../../../components";
import { TableCheck } from "../../../components";
import { Table } from "../../../components/Table";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome",
    text: "Nome",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "nome_user",
    text: "Usuário",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "cpf_cnpj",
    text: "CPF/CNPJ",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "fone1",
    text: "Fone",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "pes_fisica",
    text: "Pessoa Física",
    align: "center",
    headerAlign: "center",
    formatter: (cell) => <TableCheck value={cell} />,
  },
  {
    dataField: "nome_cidade",
    text: "Cidade",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "ativo",
    text: "Ativo",
    align: "center",
    headerAlign: "center",
    formatter: (cell) => <TableCheck value={cell} />,
  },
];

export const ClienteGrid = ({ data, setSelected }) => {
  return (
    <Table data={data} columns={columns} onSelect={setSelected} paginated />
  );
};
