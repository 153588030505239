import React from "react";
import { TableCheck, TableDelete, TableEdit } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { Table } from "../../../../components/Table";

export const PortasPedidoGrid = ({ data, handleDeleteItem }) => {
  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "tipo_material",
      text: "Material",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "descricao",
      text: "Nome da Porta",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vao",
      text: "Vão",
      align: "center",
      headerAlign: "center",
      // A: Altura | L: Largura | E: Espessura
      formatter: (_, row) =>
        `A: ${formatNumber(row.vao_altura, true, 0, true)} ` +
        `L: ${formatNumber(row.vao_largura, true, 0, true)} ` +
        `E: ${formatNumber(row.vao_espessura, true, 0, true)}`,
    },
    {
      dataField: "folha_pronta",
      text: "Folha Pronta",
      align: "center",
      headerAlign: "center",
      // A: Altura | L: Largura
      formatter: (_, row) =>
        `A: ${formatNumber(row.fol_altura_pronta, true, 0, true)} ` +
        `L: ${formatNumber(row.fol_largura_pronta, true, 0, true)}`,
    },
    {
      dataField: "marco",
      text: "Marco",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "modo_abertura",
      text: "Abertura",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "lado_abertura",
      text: "Lado",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "acabamento",
      text: "Cor - Negativo",
      align: "left",
      headerAlign: "left",
      formatter: (_, row) => {
        if (row.nome_negativo) {
          return `${row.nome_cor_acabamento} - ${row.nome_negativo}`;
        } else {
          return row.nome_cor_acabamento;
        }
      },
    },
    {
      dataField: "vista",
      text: "Vista",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "fechadura",
      text: "Fechadura",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "tem_montagem",
      text: "Pré-Montada",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "tem_usinagem",
      text: "Usinagem",
      align: "center",
      headerAlign: "center",
      formatter: (cell) => <TableCheck value={cell} />,
    },
    {
      dataField: "usinagens_especiais",
      text: "Usinagens",
      align: "left",
      headerAlign: "left",
      formatter: (_, row) =>
        row?.usinagens_especiais?.map((e) => (
          <>
            {e.nome}
            <br />
          </>
        )),
    },
    {
      dataField: "vlr_total",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act_delete",
      text: "",
      align: "center",
      headerAlign: "center",
      formatter: (c, row) => (
        <TableDelete
          onClick={() => {
            if (handleDeleteItem) {
              handleDeleteItem(row.id);
            }
          }}
        />
      ),
    },
  ];

  return <Table data={data} columns={columns} paginated selectable={false} />;
};
