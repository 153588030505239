import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { CadastroClienteModal } from "./components/CadastroClienteModal";
import { ClienteGrid } from "./components/ClienteGrid";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
  SearchInput,
} from "../../components";
import { MODAL_ACTIONS, debounce, defaultDebounceTime } from "../../coreUtils";
import { apiGet } from "../../api";

export const Cliente = () => {
  const [pista, setPista] = useState("");
  const [idCidade, setIdCidade] = useState(null);
  const [somenteAtivos, setSomenteAtivos] = useState(true);
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);

  const buscarDados = async (p) => {
    const ret = await apiGet("/cadastro/cliente/listar", {
      pista: p,
      id_cidade: idCidade,
      somente_ativos: somenteAtivos,
    });

    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    await buscarDados(v);
    setLoadingPista(false);
  }, defaultDebounceTime);

  const notifyEvent = (action) => {
    carregarDados();
    if (action === MODAL_ACTIONS.DELETE) setSelected(null);
  };

  return (
    <PageContainer title="Cadastro de Clientes" number="0003">
      <Card body>
        <Row>
          <SearchInput md={5} onChange={handlePista} loading={loadingPista} />
          <AsyncComboBox
            md={3}
            label="Cidade"
            isConcatField
            concatModelName="cidade"
            value={idCidade}
            onChange={setIdCidade}
            isClearable
          />
          <FormCheckbox
            label="Somente Ativos"
            checked={somenteAtivos}
            onChange={setSomenteAtivos}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row>
          <CadastroClienteModal selected={selected} notifyEvent={notifyEvent} />
        </Row>
      </Card>
      <Card body>
        <ClienteGrid data={dados} setSelected={setSelected} />
      </Card>
    </PageContainer>
  );
};
