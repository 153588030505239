import React from "react";
import { useHistory } from "react-router-dom";
import FormButton from "./FormButton";

export const LinkButton = ({
  pathname,
  state,
  md,
  color,
  disabled,
  padded,
  className,
  divClassName,
  divStyle,
  style,
  loading,
  children,
  disabledHint,
  id,
}) => {
  const history = useHistory();
  return (
    <FormButton
      md={md}
      color={color}
      disabled={disabled}
      padded={padded}
      className={className}
      divClassName={divClassName}
      divStyle={divStyle}
      style={style}
      loading={loading}
      disabledHint={disabledHint}
      id={id}
      onClick={() => history.push(pathname, state)}
    >
      {children}
    </FormButton>
  );
};
