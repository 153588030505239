import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { handleFocus } from "../coreUtils";

export const TableCombobox = forwardRef(
  (
    {
      id,
      name,
      autoFocus,
      tabOrder,
      className,
      options = [],
      onBlur,
      value,
      onChange,
    },
    ref
  ) => {
    const [internalId] = useState(
      id ?? "table-combobox-" + Math.floor(Math.random() * Date.now())
    );
    const [callOnBlur, setCallOnBlur] = useState(false);
    const inputRef = useRef("");

    const onKeyDown = (e) => {
      handleFocus(e);
    };

    const handleOnChange = (e) => {
      e.preventDefault();
      if (onChange) {
        const selValue = e.target.value || "";
        setCallOnBlur(true);
        onChange(selValue, e);
      }
    };

    const onBlurInternal = (e) => {
      if (onBlur) {
        const selValue = e.target.value || "";
        if (callOnBlur) onBlur(selValue, e);
      }
      setCallOnBlur(false);
    };

    useImperativeHandle(ref, () => ({
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
    }));

    return (
      <select
        id={internalId}
        autoFocus={autoFocus}
        name={name}
        tabOrder={tabOrder}
        className={className}
        ref={inputRef}
        onKeyDown={onKeyDown}
        onChange={handleOnChange}
        onBlur={onBlurInternal}
        value={options.findIndex((e) => e.value === value) > -1 ? value : null}
      >
        <option disabled selected value hidden>
          Selecione...
        </option>
        {options.map((e) => (
          <option value={e.value}>{e.label}</option>
        ))}
      </select>
    );
  }
);
