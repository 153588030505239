import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { GerenciamentoPedidosGrid } from "./components/GerenciamentoPedidosGrid";
import {
  BotaoPesquisar,
  PageContainer,
  FiltroPeriodoDatas,
  LinkButton,
  FormButton,
  IntegerInput,
  ComboBox,
  ModalBase,
} from "../../../components";
import { apiGet, apiPut } from "../../../api";
import { PedidoPDF } from "./pdf/PedidoPDF";
import { printReport } from "../../../coreUtils";
import {
  incluirComponentesRoute,
  incluirKitPortaAbrirRoute,
  incluirKitPortaCorrerRoute,
} from "../../../routes/modules/pedidos";
import moment from "moment";

const statusPedido = [
  { label: "Em Digitação", value: "DIG" },
  { label: "Aguardando Envio para Ecoplac", value: "AEE" },
];

export const GerenciamentoPedidos = () => {
  const [numPedido, setNumPedido] = useState(null);
  const [status, setStatus] = useState("DIG");
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTipoPedido, setSelectedTipoPedido] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImpPedido, setLoadingImpPedido] = useState(false);
  const [loadingEnvioEcoplac, setLoadingEnvioEcoplac] = useState(false);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [modalTipoPedidoIsOpen, setModalTipoPedidoIsOpen] = useState(false);

  const buscarDados = async () => {
    const params = {
      status: status,
      numero: numPedido,
      data_ini: moment(dataIni).format("YYYY-MM-DD"),
      data_fim: moment(dataFim).format("YYYY-MM-DD"),
    };
    const ret = await apiGet("/pedido/listar/", params);
    setDados(ret ?? []);
  };

  const carregarDados = async () => {
    setLoading(true);
    await buscarDados();
    setLoading(false);
  };

  useEffect(() => {
    buscarDados();
  }, []);

  const imprimirPedido = async () => {
    setLoadingImpPedido(true);
    const ret = await apiGet(`/pedido/impressao/buscar/${selected}/`);
    if (ret) {
      await printReport(<PedidoPDF data={ret} />, "0001");
    }
    setLoadingImpPedido(false);
  };

  const enviarPedidoParaEcoplac = async () => {
    setLoadingEnvioEcoplac(true);
    try {
      const ret = await apiPut(`/pedido/enviar_para_ecoplac/`, {
        id_pedido: selected,
      });
      if (ret) {
        carregarDados();
      }
    } finally {
      setLoadingEnvioEcoplac(false);
    }
  };

  let routeAlterar = null;
  if (selectedTipoPedido === "KABR") {
    routeAlterar = incluirKitPortaAbrirRoute.path;
  } else if (selectedTipoPedido === "KCOR") {
    routeAlterar = incluirKitPortaCorrerRoute.path;
  } else if (selectedTipoPedido === "COMP") {
    routeAlterar = incluirComponentesRoute.path;
  }

  return (
    <PageContainer title="Pedidos" number="0001">
      <ModalBase
        isOpen={modalTipoPedidoIsOpen}
        title="Selecione o tipo de pedido"
        size="md"
        toggle={() => setModalTipoPedidoIsOpen(false)}
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Col md="auto">
            <LinkButton
              pathname={incluirKitPortaAbrirRoute.path}
              padded={false}
              md="auto"
              color="info"
            >
              Kit Porta - Abrir
            </LinkButton>
          </Col>
          <Col md="auto">
            <LinkButton
              pathname={incluirKitPortaCorrerRoute.path}
              padded={false}
              md="auto"
              color="secondary"
            >
              Kit Porta - Correr
            </LinkButton>
          </Col>
          <Col md="auto">
            <LinkButton
              pathname={incluirComponentesRoute.path}
              padded={false}
              md="auto"
              color="primary"
            >
              Componentes
            </LinkButton>
          </Col>
        </Row>
      </ModalBase>
      <Card body>
        <Row>
          <IntegerInput
            label="Nº do Pedido"
            value={numPedido}
            onChange={setNumPedido}
            md={2}
          />
          <ComboBox
            label="Status do Pedido"
            md={3}
            options={statusPedido}
            value={status}
            onChange={setStatus}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas
            defaultOption="HOJE"
            onChange={(dIni, dFim) => {
              setDataIni(dIni);
              setDataFim(dFim);
            }}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <FormButton md="auto" onClick={() => setModalTipoPedidoIsOpen(true)}>
            Novo Pedido
          </FormButton>
          <LinkButton
            pathname={routeAlterar}
            padded={true}
            md="auto"
            color="warning"
            state={{ idPedidoAlterar: selected }}
          >
            Alterar
          </LinkButton>
          <FormButton
            md="auto"
            loading={loadingImpPedido}
            onClick={imprimirPedido}
            color="primary"
            disabled={selected == null}
          >
            Imprimir
          </FormButton>
          <FormButton
            md="auto"
            loading={loadingEnvioEcoplac}
            onClick={enviarPedidoParaEcoplac}
            color="success"
            disabled={selectedStatus != "AEE"}
          >
            Enviar para Ecoplac
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <GerenciamentoPedidosGrid
          data={dados}
          setSelected={(id, _, row) => {
            setSelected(id);
            setSelectedStatus(row?.status);
            setSelectedTipoPedido(row?.tipo_pedido);
          }}
        />
      </Card>
    </PageContainer>
  );
};
