import React from "react";
import { components } from "react-select";

const ComboBoxOptionWithPhoto = (props) => {
  return (
    <components.Option {...props}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{ display: "flex", marginTop: "auto", marginBottom: "auto" }}
        >
          {props.label}
        </span>
        {props.data.foto_base64 && (
          <img
            src={props.data.foto_base64}
            height={120}
            style={{ paddingLeft: "5px" }}
          />
        )}
      </div>
    </components.Option>
  );
};

export default ComboBoxOptionWithPhoto;
