import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InputMask from "react-input-mask";
import { Col, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { handleFocus } from "../coreUtils";

const MaskedInput = forwardRef(
  (
    {
      md,
      mdInput = 12,
      label,
      type = "text",
      name,
      onChange,
      value,
      className,
      inline = false,
      check = false,
      required = false,
      invalid = false,
      disabled = false,
      placeholder = "",
      mask,
      maskPlaceholder,
      maskChar,
      id,
    },
    ref
  ) => {
    const [innerValidation, setInnerValidation] = useState(true);
    const [internalValue, setInternalValue] = useState("");
    const [internalId] = useState(
      id ?? "mi-" + Math.floor(Math.random() * Date.now())
    );

    const inputRef = useRef("");

    const validateInternally = (e) => {
      if (required && e.target.value === "") {
        setInnerValidation(false);
      } else {
        setInnerValidation(true);
      }
    };

    useEffect(() => setInternalValue(value?.toUpperCase()), [value]);

    const handleOnChange = (e) => {
      e.preventDefault();
      const inputValue = e.target.value?.toUpperCase() || "";
      setInternalValue(inputValue);
      if (onChange) {
        onChange(inputValue, e);
      }
    };

    useImperativeHandle(ref, () => ({
      isValid: () => innerValidation && !invalid,
      focus: () =>
        setTimeout(() => inputRef.current && inputRef.current.focus(), 35),
      value: internalValue?.toUpperCase()?.trim(),
      setValue: (val) => setInternalValue(val),
    }));

    return (
      <Col md={md}>
        <FormGroup check={check} inline={inline}>
          <Label check={check} for={internalId}>
            {label}
          </Label>
          <Col md={mdInput} className="no-gutters">
            <InputMask
              mask={mask}
              onChange={handleOnChange}
              value={internalValue}
              onBlur={validateInternally}
              disabled={disabled}
              maskPlaceholder={maskPlaceholder}
              maskChar={maskChar}
            >
              {() => (
                <Input
                  type={type}
                  name={name}
                  defaultValue={value}
                  innerRef={inputRef}
                  className={className}
                  required={required}
                  invalid={!innerValidation || invalid}
                  placeholder={placeholder}
                  onKeyDown={handleFocus}
                  disabled={disabled}
                  id={internalId}
                />
              )}
            </InputMask>
          </Col>
          <FormFeedback valid={false}>Esse campo é obrigatório</FormFeedback>
        </FormGroup>
      </Col>
    );
  }
);

export default MaskedInput;
