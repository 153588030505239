import React, { useState } from "react";
import { Col, CustomInput, Label } from "reactstrap";

export const FormCheckbox = ({
  label,
  md = "auto",
  id,
  onChange,
  checked,
  padded = true,
  disabled,
  style,
  className,
}) => {
  const [internalId] = useState(
    id ?? "ch-" + Math.floor(Math.random() * Date.now())
  );

  return (
    <Col md={md} style={style}>
      {padded && <Label>&#8205;</Label>}
      <CustomInput
        type="checkbox"
        id={internalId}
        checked={checked}
        onChange={() => onChange && onChange(!checked)}
        htmlFor={internalId}
        label={label}
        disabled={disabled}
        className={className}
      />
    </Col>
  );
};
